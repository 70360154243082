import store from '@/store';

const handleDownload = (blob, title) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = title;
    document.body.appendChild(a);
    a.click();
    a.remove();
};

export const forceFileDownload = (url, title, reportsApi = false, v2Api = false) => {
    let fullUrl;
    if (reportsApi) {
        fullUrl = `${store.state.generalConfigurations.apiReportsUrl}${url}`;
    } else if (v2Api) {
        fullUrl = `${store.state.generalConfigurations.apiV2Url}${url}`;
    } else {
        fullUrl = `${store.state.generalConfigurations.apiUrl}${url}`;
    }
    return fetch(fullUrl, {
        method: 'GET',
        headers: new Headers({
            'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token'),
            responseType: 'blob'
        })
    })
        .then(response => response.blob())
        .then(blob => handleDownload(blob, title));
};

export const forceExternalFileDownload = (url) => {
    const title = url.substring(url.lastIndexOf('/') + 1).split('?')[0];

    return fetch(url, {
        method: 'GET',
        headers: new Headers({
            responseType: 'blob'
        })
    })
        .then(response => response.blob())
        .then(blob => handleDownload(blob, title));
};

export const convertFilesToBase64 = (files) => {
    const resultArray = [];

    files.forEach(f => {
        const reader = new FileReader();
        reader.readAsDataURL(f.file);
        const addFiles = (result) => {
            resultArray.push({
                name: f.name,
                content: result
            });
        };
        reader.onloadend = function () {
            addFiles(reader.result);
        };
    });

    return resultArray;
};

export const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
};

export const base64ToArrayBuffer = (_base64Str) => {
    const cleanStr = _base64Str.substring(_base64Str.indexOf(',') + 1);
    const binaryString = window.atob(cleanStr);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
};

export const getMimeType = (string) => {
    const result = string.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/);
    if (result) {
        return string.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    } else {
        return null;
    }
};

export const showBase64Document = (_base64Str, _contentType) => {
    if (!_contentType) {
        _contentType = getMimeType(_base64Str);
    }
    const byte = base64ToArrayBuffer(_base64Str);
    const blob = new Blob([byte], { type: _contentType });
    window.open(URL.createObjectURL(blob), '_blank');
};

export const downloadBase64Document = (_base64Str, title, _contentType) => {
    if (!_contentType) {
        _contentType = getMimeType(_base64Str);
    }

    const byte = base64ToArrayBuffer(_base64Str);
    const blob = new Blob([byte], { type: _contentType });

    handleDownload(blob, title);
};

export const isImage = (string) => {
    const type = getMimeType(string);
    const regex = new RegExp('image');
    return regex.test(type);
};

const fileType = require('file-type');
export const createBase64WithMimeType = (str) => {
    const _buffer = Buffer.from(str, 'base64');

    return fileType.fromBuffer(_buffer);
};
