import { getV2Endpoint } from '@/utils/apiV2Endpoints';
import KmsHttpService from '@/servises/kmsHttpService';

export default {
    getIncidents: (params) => {
        const endpoint = getV2Endpoint({ 'group': 'incidents', 'endpoint': 'list' }, null);
        return KmsHttpService.get(endpoint, params);
    },
    createIncident: (params) => {
        const endpoint = getV2Endpoint({ 'group': 'incidents', 'endpoint': 'list' }, null);
        return KmsHttpService.post(endpoint, params);
    },
    updateIncident: (id, params) => {
        const endpoint = getV2Endpoint({ 'group': 'incidents', 'endpoint': 'manage' }, { id: id });
        return KmsHttpService.patch(endpoint, params);
    },
    deleteIncident: (id) => {
        const endpoint = getV2Endpoint({ 'group': 'incidents', 'endpoint': 'manage' }, { id: id });
        return KmsHttpService.delete(endpoint, null);
    },
    getIncidentComments: (id) => {
        const endpoint = getV2Endpoint({ 'group': 'comments', 'endpoint': 'list' }, { incident_id: id });
        return KmsHttpService.get(endpoint, null);
    },
    createIncidentComment: (id, params) => {
        const endpoint = getV2Endpoint({ 'group': 'comments', 'endpoint': 'send' }, { incident_id: id });
        return KmsHttpService.post(endpoint, params);
    },
    confirmCreation: (id) => {
        const endpoint = getV2Endpoint({ 'group': 'incidents', 'endpoint': 'confirm_creation' }, { incident_id: id });
        return KmsHttpService.post(endpoint, null);
    },
    getPdfReport: (id) => {
        return getV2Endpoint({ 'group': 'incidents', 'endpoint': 'get_pdf_report' }, { id: id });
    },
    sendReport: (id, params) => {
        return KmsHttpService.post(getV2Endpoint({ 'group': 'incidents', 'endpoint': 'send_report' }, { id: id }), params);
    }
};
