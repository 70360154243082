import { getEndpoint, setParams } from '@/utils/endpoints';
import { forceFileDownload } from '@/helpers/files';
import employeesApi from '@/utils/api/employees';
import employeesApiV2 from '@/utils/api-v2/employees';

export default {
    namespaced: true,
    state: () => ({
        rawEmployeesWithAccess: [],
        rawInspectors: [],
        rawEmployees: [],
        rawEmployeesForSelect: [],
        rawResponsibleEmployees: [],
        totalEmployeesAmount: null,
        totalEmployeesAmountForSelect: null,
        totalResponsibleEmployeesAmount: null,
        totalInspectorsAmount: null,
        rawEmployeesWithEmail: [],
        totalEmployeesWithEmailAmount: null,
        rawEmployeesWithPhone: [],
        totalEmployeesWithPhoneAmount: null,
        rawEmployeeAssignedSubContractors: []
    }),
    getters: {
        employeesWithAccess: (state) => {
            return state.rawEmployeesWithAccess.map(el => ({
                value: el.id,
                text: el.full_name
            }));
        },
        inspectors: (state) => {
            return state.rawInspectors.map(el => ({
                value: el.id,
                text: `${el.full_name}`
            }));
        },
        employees: state => {
            return state.rawEmployees.map(el => ({
                value: el.id,
                text: `${el.full_name}`
            }));
        },
        employeesForSelect: state => {
            return state.rawEmployeesForSelect.map(el => ({
                value: el.id,
                text: `${el.full_name}`
            })).sort((a, b) => a.text.localeCompare(b.text));
        },
        responsibleEmployees: state => {
            return state.rawResponsibleEmployees.map(el => ({
                value: el.id,
                text: `${el.full_name}`
            }));
        },
        employeesWithEmail: state => {
            return state.rawEmployeesWithEmail.map(el => ({
                value: el.id,
                text: `${el.full_name} (${el.email})`
            }));
        },
        employeesWithPhone: state => {
            return state.rawEmployeesWithPhone.map(el => ({
                value: el.id,
                text: `${el.full_name} (${el.phone})`
            }));
        },
        employeeAssignedSubContractors: (state) => {
            return state.rawEmployeeAssignedSubContractors.length > 0 ? state.rawEmployeeAssignedSubContractors[0].subcontractors : [];
        }
    },
    mutations: {
        SET_RAW_EMPLOYEES_WITH_ACCESS (state, payload) {
            state.rawEmployeesWithAccess = payload;
        },
        SET_RAW_INSPECTORS (state, payload) {
            state.rawInspectors = payload;
        },
        SET_RAW_EMPLOYEES (state, payload) {
            state.rawEmployees = payload;
        },
        SET_RAW_EMPLOYEES_FOR_SELECT (state, payload) {
            state.rawEmployeesForSelect = payload;
        },
        SET_TOTAL_EMPLOYEES_AMOUNT (state, payload) {
            state.totalEmployeesAmount = payload;
        },
        SET_TOTAL_ITEMS_AMOUNT (state, payload) {
            state.totalItemsAmount = payload;
        },
        SET_TOTAL_EMPLOYEES_AMOUNT_FOR_SELECT (state, payload) {
            state.totalEmployeesAmountForSelect = payload;
        },
        SET_RAW_RESPONSIBLE_EMPLOYEES (state, payload) {
            state.rawResponsibleEmployees = payload;
        },
        SET_RAW_EMPLOYEES_WITH_EMAIL (state, payload) {
            state.rawEmployeesWithEmail = payload;
        },
        SET_RAW_EMPLOYEES_WITH_PHONE (state, payload) {
            state.rawEmployeesWithPhone = payload;
        },
        SET_TOTAL_RESPONSIBLE_EMPLOYEES_AMOUNT (state, payload) {
            state.totalResponsibleEmployeesAmount = payload;
        },
        SET_TOTAL_INSPECTORS_AMOUNT (state, payload) {
            state.totalInspectorsAmount = payload;
        },
        SET_TOTAL_EMPLOYEES_WITH_EMAIL_AMOUNT (state, payload) {
            state.totalEmployeesWithEmailAmount = payload;
        },
        SET_TOTAL_EMPLOYEES_WITH_PHONE_AMOUNT (state, payload) {
            state.totalEmployeesWithPhoneAmount = payload;
        },
        SET_RAW_EMPLOYEE_ASSIGNED_SUBCONSTRACTORS (state, payload) {
            state.rawEmployeeAssignedSubContractors = payload;
        }
    },
    actions: {
        getEmployeesWithAccess ({ commit }, value = null) {
            const params = {
                'filter[is_client]': true,
                'stats[total]': 'count',
                sort: 'full_name'
            };

            if (value) {
                params['filter[full_name][match]'] = value;
            }

            return employeesApi.getEmployees(params)
                .then(({ data }) => {
                    commit('SET_RAW_EMPLOYEES_WITH_ACCESS', data.data.map(el => {
                        return {
                            id: el.id,
                            ...el.attributes
                        };
                    }));
                });
        },
        getInspectors ({ commit }, additionalParams = null) {
            const params = {
                'filter[is_inspector]': true,
                'stats[total]': 'count',
                sort: 'full_name'
            };

            setParams(params, additionalParams);

            return employeesApi.getEmployees(params)
                .then(({ data }) => {
                    commit('SET_RAW_INSPECTORS', data.data.map(el => {
                        return {
                            id: el.id,
                            ...el.attributes
                        };
                    }));

                    if (data.meta.stats) {
                        commit('SET_TOTAL_INSPECTORS_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getInspectorsLazyLoading ({ commit, state }, additionalParams = null) {
            const params = {
                'filter[is_inspector]': true,
                'stats[total]': 'count',
                sort: 'full_name'
            };

            setParams(params, additionalParams);

            return employeesApi.getEmployees(params)
                .then(({ data }) => {
                    const result = [...state.rawInspectors].concat(data.data.map(el => ({
                        id: el.id,
                        ...el.attributes
                    })));

                    commit('SET_RAW_INSPECTORS', result);

                    if (data.meta.stats) {
                        commit('SET_TOTAL_INSPECTORS_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getEmployees ({ commit }, additionalParams = null) {
            commit('SET_RAW_EMPLOYEES', []);

            const params = {
                'stats[total]': 'count',
                sort: 'full_name'
            };

            setParams(params, additionalParams);

            return employeesApi.getEmployees(params)
                .then(({ data }) => {
                    commit('SET_RAW_EMPLOYEES', data.data.map(el => {
                        return {
                            id: el.id,
                            ...el.attributes
                        };
                    }));

                    if (params['filter[is_item]']) {
                        commit('SET_TOTAL_ITEMS_AMOUNT', data.meta.stats.total.count);
                    }
                    if (data.meta.stats) {
                        commit('SET_TOTAL_EMPLOYEES_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getEmployeesLazyLoading ({ commit, state }, additionalParams = null) {
            const params = {
                'stats[total]': 'count',
                sort: 'full_name'
            };

            setParams(params, additionalParams);
            return employeesApi.getEmployees(params)
                .then(({ data }) => {
                    const result = [...state.rawEmployees].concat(data.data.map(el => ({
                        id: el.id,
                        ...el.attributes
                    })));

                    commit('SET_RAW_EMPLOYEES', result);

                    if (data.meta.stats) {
                        commit('SET_TOTAL_EMPLOYEES_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getEmployeesForSelect ({ commit }, additionalParams = null) {
            commit('SET_RAW_EMPLOYEES_FOR_SELECT', []);

            const params = {
                'stats[total]': 'count',
                sort: 'full_name'
            };

            setParams(params, additionalParams);

            return employeesApi.getEmployees(params)
                .then(({ data }) => {
                    commit('SET_RAW_EMPLOYEES_FOR_SELECT', data.data.map(el => {
                        return {
                            id: el.id,
                            ...el.attributes
                        };
                    }));
                    if (data.meta.stats) {
                        commit('SET_TOTAL_EMPLOYEES_AMOUNT_FOR_SELECT', data.meta.stats.total.count);
                    }
                });
        },
        getEmployeesLazyLoadingForSelect ({ commit, state }, additionalParams = null) {
            const params = {
                'stats[total]': 'count',
                sort: 'full_name'
            };

            setParams(params, additionalParams);

            return employeesApi.getEmployees(params)
                .then(({ data }) => {
                    const result = [...state.rawEmployeesForSelect].concat(data.data.map(el => ({
                        id: el.id,
                        ...el.attributes
                    })));

                    commit('SET_RAW_EMPLOYEES_FOR_SELECT', result);

                    if (data.meta.stats) {
                        commit('SET_TOTAL_EMPLOYEES_AMOUNT_FOR_SELECT', data.meta.stats.total.count);
                    }
                });
        },
        getResponsibleEmployees ({ commit }, additionalParams = null) {
            const params = {
                'stats[total]': 'count',
                'filter[is_item]': false,
                sort: 'full_name'
            };

            setParams(params, additionalParams);

            return employeesApi.getEmployees(params)
                .then(({ data }) => {
                    commit('SET_RAW_RESPONSIBLE_EMPLOYEES', data.data.map(el => {
                        return {
                            id: el.id,
                            ...el.attributes
                        };
                    }));
                    if (data.meta.stats) {
                        commit('SET_TOTAL_RESPONSIBLE_EMPLOYEES_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getResponsibleEmployeesLazyLoading ({ commit, state }, additionalParams = null) {
            const params = {
                'stats[total]': 'count',
                'filter[is_item]': false,
                sort: 'full_name'
            };

            setParams(params, additionalParams);

            return employeesApi.getEmployees(params)
                .then(({ data }) => {
                    const result = [...state.rawResponsibleEmployees].concat(data.data.map(el => ({
                        id: el.id,
                        ...el.attributes
                    })));

                    commit('SET_RAW_RESPONSIBLE_EMPLOYEES', result);

                    if (data.meta.stats) {
                        commit('SET_TOTAL_RESPONSIBLE_EMPLOYEES_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getEmployeesWithEmail ({ commit }, additionalParams = null) {
            const params = {
                'stats[total]': 'count',
                sort: 'full_name',
                'filter[has_email]': true
            };

            setParams(params, additionalParams);

            return employeesApi.getEmployees(params)
                .then(({ data }) => {
                    commit('SET_RAW_EMPLOYEES_WITH_EMAIL', data.data.map(el => {
                        return {
                            id: el.id,
                            ...el.attributes
                        };
                    }));

                    if (data.meta.stats) {
                        commit('SET_TOTAL_EMPLOYEES_WITH_EMAIL_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getEmployeesWithEmailLazyLoading ({ commit, state }, additionalParams = null) {
            const params = {
                'stats[total]': 'count',
                sort: 'full_name',
                'filter[has_email]': true
            };

            setParams(params, additionalParams);

            return employeesApi.getEmployees(params)
                .then(({ data }) => {
                    const result = [...state.rawEmployeesWithEmail].concat(data.data.map(el => ({
                        id: el.id,
                        ...el.attributes
                    })));

                    commit('SET_RAW_EMPLOYEES_WITH_EMAIL', result);

                    if (data.meta.stats) {
                        commit('SET_TOTAL_EMPLOYEES_WITH_EMAIL_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getEmployeesWithPhone ({ commit }, additionalParams = null) {
            const params = {
                'stats[total]': 'count',
                sort: 'full_name',
                'filter[has_phone]': true
            };

            setParams(params, additionalParams);

            return employeesApi.getEmployees(params)
                .then(({ data }) => {
                    commit('SET_RAW_EMPLOYEES_WITH_PHONE', data.data.map(el => {
                        return {
                            id: el.id,
                            ...el.attributes
                        };
                    }));

                    if (data.meta.stats) {
                        commit('SET_TOTAL_EMPLOYEES_WITH_PHONE_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getEmployeesWithPhoneLazyLoading ({ commit, state }, additionalParams = null) {
            const params = {
                'stats[total]': 'count',
                sort: 'full_name',
                'filter[has_phone]': true
            };

            setParams(params, additionalParams);

            return employeesApi.getEmployees(params)
                .then(({ data }) => {
                    const result = [...state.rawEmployeesWithPhone].concat(data.data.map(el => ({
                        id: el.id,
                        ...el.attributes
                    })));

                    commit('SET_RAW_EMPLOYEES_WITH_PHONE', result);

                    if (data.meta.stats) {
                        commit('SET_TOTAL_EMPLOYEES_WITH_PHONE_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        setAccessToTemplates (context, payload) {
            const data = {
                data: {
                    id: payload.employeeId,
                    type: 'employees',
                    attributes: {
                        template_ids: payload.templateId
                    }
                }
            };

            return employeesApi.setAccessToTemplates(payload.employeeId, data);
        },
        getAllEmployeesQrCodes ({ rootState }) {
            const queryParams = new URLSearchParams();
            queryParams.set('locale', rootState.locale.currentLocale);

            const url = `${getEndpoint('get_all_employees_qrcode_pdf')}?${queryParams}`;

            return forceFileDownload(url, 'employees_qr_codes');
        },
        getSelectedEmployeesQRCodes ({ rootState }, ids) {
            const queryParams = new URLSearchParams();
            queryParams.set('filter[id]', ids.join(','));
            queryParams.set('locale', rootState.locale.currentLocale);

            const url = `${getEndpoint('get_employees_selected_qrcodes')}?${queryParams}`;

            return forceFileDownload(url, 'employees_qr_codes.pdf');
        },
        importEmployees (context, file) {
            return employeesApi.importEmployees({
                data: {
                    file: file
                }
            });
        },
        manageAssignmentToLocations (context, data) {
            return employeesApi.manageAssignmentToLocations(data);
        },

        getEmployeeSubContractors ({ commit }, params = null) {
            return employeesApiV2.getEmployeeSubContractors(params)
                .then(({ data }) => {
                    commit('SET_RAW_EMPLOYEE_ASSIGNED_SUBCONSTRACTORS', data.map(el => {
                        const subContractors = el.subcontractors_email;
                        for (const sms of el.subcontractors_sms) {
                            if (!subContractors.some(item => item.id === sms.id)) {
                                subContractors.push(sms);
                            }
                        }
                        return {
                            ...el,
                            subcontractors: subContractors.sort((a, b) => a.name.localeCompare(b.name))
                        };
                    }));
                });
        }
    }
};
