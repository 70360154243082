import axios from 'axios';
import { onFulfilled, onRejected } from '@/servises/interceptor';

const AUTH_TOKEN = localStorage.getItem('jwt_access_token');

export class HttpService {
    constructor () {
        this.axios = axios.create({
            headers: {
                'Content-type': 'application/json;charset=UTF-8',
                'agency': window.location.host.split('.')[0]
            },
            withCredentials: true
        });

        this.axios.interceptors.response.use(onFulfilled, onRejected);

        if (AUTH_TOKEN) {
            this.axios.defaults.headers.common.Authorization = 'Bearer ' + AUTH_TOKEN;
        }
    }

    setAuthHeader (token) {
        this.axios.defaults.headers.common.Authorization = 'Bearer ' + token;
    }

    removeAuthHeader () {
        delete this.axios.defaults.headers.common.Authorization;
    }

    setBaseUrl (url) {
        this.axios.defaults.baseURL = url;
    }

    get (url, params) {
        return this.axios.get(url, params);
    }

    post (url, data, params = null) {
        return this.axios.post(url, data, params);
    }

    postFile (url, data, params = null) {
        url = this.axios.defaults.baseURL + url;

        const form = new FormData();
        for (const name in data) {
            form.append(name, data[name]);
        }
        axios.post(url, form, params);
    }

    put (url, data) {
        return this.axios.put(url, data);
    }

    patch (url, data, params = null) {
        return this.axios.patch(url, data, params);
    }

    delete (url, params) {
        return this.axios.delete(url, params);
    }

    all (iterable) {
        return axios.all(iterable);
    }

    spread (callback) {
        return axios.spread(callback);
    }
}

export default new HttpService();
