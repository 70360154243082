import { getField, updateField } from 'vuex-map-fields';
import incidentsApi from '@/utils/api/incidents';
import incidentsApiV2 from '@/utils/api-v2/incidents';
import Incident from '@/models/incidents/incident';
import IncidentType from '@/models/incidentTypes/incidentTypes';
import IncidentStatus from '@/models/incidetStatuses/incidentStatus';
import { PARSE_DATE, PARSE_TIME, CONVERT_FROM_UTC, DATE_NOW_IN_LOCAL_TIME } from '@/helpers/dates';
import { forceFileDownload, createBase64WithMimeType } from '@/helpers/files';
import inspectionsApi from '@/utils/api-v2/inspections';
import { WEB_TYPES_TO_SKIP } from '@/constants/questionTypes';

const getId = (string) => string.substring(string.lastIndexOf('/') + 1);

export default {
    namespaced: true,
    state: () => ({
        incidentFormVisibility: false,
        incidentTypeFormVisibility: false,
        incidentStatusFormVisibility: false,
        incidentData: new Incident(),
        incidentTypeData: new IncidentType(),
        incidentStatusData: new IncidentStatus(),
        editedIncidentId: null,
        editedIncidentTypeId: null,
        editedIncidentStatusId: null,
        activeIncident: null,
        activeIncidentType: null,
        showDetails: false,
        incidentHistory: [],
        incidentQuestions: [],
        incidentComments: [],
        incidentCommentsTotalAmount: null,
        incidentCommentContent: null
    }),
    getters: {
        getField,
        activeIncidentId: state => state.activeIncident?.id
    },
    mutations: {
        updateField,
        SET_INCIDENT_DATA (state, payload) {
            const result = new Incident();
            result.parseData(payload);

            state.incidentData = result;
        },
        SET_INCIDENT_TYPE_DATA (state, payload) {
            state.incidentTypeData = new IncidentType(payload);
        },
        SET_STATUS_FORM_VISIBILITY (state, payload) {
            state.incidentStatusFormVisibility = payload;
        },
        SET_ACTIVE_STATUS_ID (state, payload) {
            state.editedIncidentStatusId = payload;
        },
        SET_INCIDENT_STATUS_DATA (state, payload) {
            state.incidentStatusData = new IncidentStatus(payload);
        },
        CLEAR_INCIDENT_DATA (state) {
            state.incidentData = new Incident();
        },
        CLEAR_INCIDENT_TYPE_DATA (state) {
            state.incidentTypeData = new IncidentType();
        },
        CLEAR_INCIDENT_STATUS_DATA (state) {
            state.incidentStatusData = new IncidentStatus();
            state.editedIncidentStatusId = null;
        },
        SET_ACTIVE_INCIDENT (state, payload) {
            let result;
            if (payload === null) {
                result = payload;
            } else {
                const localStartDateTime = payload.attributes.start_date_time_local ? payload.attributes.start_date_time_local.split('+')[0].split('T').join(' ') : '';
                const localEndDateTime = payload.attributes.end_date_time_local ? payload.attributes.end_date_time_local.split('+')[0].split('T').join(' ') : '';
                const responsibleEmployeeName = payload.attributes.responsible_employee_name;
                const dateTime = payload.attributes.start_date_time
                    ? CONVERT_FROM_UTC(payload.attributes.start_date_time) : '';
                const startDate = dateTime ? PARSE_DATE(dateTime) : '';
                const startTime = dateTime ? PARSE_TIME(dateTime) : '';

                const endDateTime = payload.attributes.end_date_time
                    ? CONVERT_FROM_UTC(payload.attributes.end_date_time) : '';
                const endDate = endDateTime ? PARSE_DATE(endDateTime) : '';
                const endTime = endDateTime ? PARSE_TIME(endDateTime) : '';

                const siteId = getId(payload.relationships.place.links.related);
                const locationId = getId(payload.relationships.point.links.related);
                const personAssignedId = payload.relationships.responsible_employee.links.related
                    ? getId(payload.relationships.responsible_employee.links.related) : null;

                const formattedFiles = [];
                payload.attributes.files_content.forEach(f => {
                    createBase64WithMimeType(f.content)
                        .then(result => {
                            formattedFiles.push({
                                name: f.name.substring(f.name.lastIndexOf('_') + 1),
                                content: `data:${result.mime};base64,${f.content}`
                            });
                        });
                });

                result = {
                    id: payload.id,
                    ...payload.attributes,
                    incidents_date: `${startDate} ${startTime}`,
                    start_date: startDate,
                    start_time: startTime,
                    end_incident_date: `${endDate} ${endTime}`,
                    end_date: endDate,
                    end_time: endTime,
                    site_id: siteId,
                    location_id: locationId,
                    person_assigned: personAssignedId,
                    responsible_employee_name: responsibleEmployeeName,
                    inspectionWithAnswersId: payload.inspectionWithAnswersId,
                    files: formattedFiles,
                    parsed_local_start_date_time: localStartDateTime,
                    parsed_local_end_date_time: localEndDateTime,
                    incidentType: payload.incidentType,
                    incidentStatus: payload.incidentStatus
                };
            }

            state.activeIncident = result;
        },
        SET_ACTIVE_INCIDENT_TYPE (state, payload) {
            let result;
            if (payload === null) {
                result = payload;
            } else {
                const templateId = payload.relationships.template.links.related
                    ? getId(payload.relationships.template.links.related) : null;
                const OldTemplateId = payload.relationships.template.links.related
                    ? getId(payload.relationships.template.links.related) : null;
                const companyTypeId = payload.relationships.company_type.links.related
                    ? getId(payload.relationships.company_type.links.related) : null;
                const incidentStatuses = payload.relationships.incident_statuses?.data.length > 0
                    ? payload.incident_statuses : [];
                const incidentMails = payload.relationships.employee_mails?.data.length > 0
                    ? payload.notifications_mail : [];
                const incidentOldMails = payload.relationships.employee_mails?.data.length > 0
                    ? payload.notifications_mail : [];
                const incidentPhones = payload.relationships.employee_phones?.data.length > 0
                    ? payload.notifications_phone : [];
                const incidentOldPhones = payload.relationships.employee_phones?.data.length > 0
                    ? payload.notifications_phone : [];
                result = {
                    id: payload.id,
                    ...payload.attributes,
                    templateId: templateId,
                    company_typeId: companyTypeId,
                    incident_statuses: incidentStatuses,
                    notifications_mail: incidentMails,
                    notifications_phone: incidentPhones,
                    previousNotifications_mail: incidentOldMails,
                    previousNotifications_phone: incidentOldPhones,
                    previousTemplateId: OldTemplateId
                };
            }

            state.activeIncidentType = result;
        },
        SET_INCIDENT_HISTORY (state, payload) {
            state.incidentHistory = payload;
        },
        SET_INCIDENT_COMMENTS (state, payload) {
            let result;
            if (payload.length === 0) {
                result = payload;
            } else {
                result = payload.map(item => {
                    const dateTime = item.date
                        ? CONVERT_FROM_UTC(item.date) : '';
                    return {
                        id: item.id,
                        employee_name: item.employee?.full_name || '',
                        ...item,
                        date_time: dateTime ? `${PARSE_DATE(dateTime)} ${PARSE_TIME(dateTime)}` : null
                    };
                });
            }

            state.incidentComments = result;
        },
        SET_INCIDENT_COMMENT_TOTAL_AMOUNT (state, payload) {
            state.incidentCommentsTotalAmount = payload;
        },
        SET_INCIDENT_QUESTIONS (state, payload) {
            state.incidentQuestions = payload;
        },
        CLEAR_INCIDENT_QUESTIONS (state) {
            state.incidentQuestions = [];
        }
    },
    actions: {
        getActiveIncident ({ commit, dispatch }, id) {
            return incidentsApi.getActiveIncident(id)
                .then(({ data }) => {
                    const activeIncidentPayload = {
                        ...data.data
                    };

                    if (data?.included) {
                        const history = data.included.filter(el => el.type === 'incident_history_items');
                        commit('SET_INCIDENT_HISTORY', history.map(item => {
                            const dateTime = item.attributes.date_time
                                ? CONVERT_FROM_UTC(item.attributes.date_time) : '';

                            return {
                                employee_name: item.attributes.employee_name,
                                status_name: item.attributes.status_name,
                                date: dateTime ? `${PARSE_DATE(dateTime)} ${PARSE_TIME(dateTime)}` : null
                            };
                        }));

                        const inspection = data.included.filter(el => el.type === 'inspections')[0];
                        if (inspection) {
                            activeIncidentPayload.inspectionWithAnswersId = inspection.id;
                            dispatch('manageInspection/getInspectionAnswers', { id: inspection.id }, { root: true });
                        }
                        const type = data.included.filter(el => el.type === 'incident_kinds')[0];
                        const incidentType = {
                            id: null,
                            name: null,
                            color: null
                        };
                        if (type) {
                            incidentType.id = type.id;
                            incidentType.name = type.attributes.name;
                            incidentType.color = type.attributes.color;
                        }
                        activeIncidentPayload.incidentType = incidentType;

                        const status = data.included.filter(el => el.type === 'incident_statuses')[0];
                        const incidentStatus = {
                            id: null,
                            name: null,
                            color: null,
                            completion_percent: null
                        };
                        if (status) {
                            incidentStatus.id = status.id;
                            incidentStatus.name = status.attributes.name;
                            incidentStatus.color = status.attributes.color;
                            incidentStatus.completion_percent = status.attributes.completion_percent;
                        }
                        activeIncidentPayload.incidentStatus = incidentStatus;
                    }

                    dispatch('getCommentsPerIncident', { id: id });

                    commit('SET_ACTIVE_INCIDENT', activeIncidentPayload);
                });
        },
        getActiveIncidentType ({ commit }, id) {
            return incidentsApi.getActiveIncidentType(id)
                .then(({ data }) => {
                    const activeIncidentTypePayload = {
                        ...data.data
                    };
                    if (data?.included) {
                        const template = data.included.filter(el => el.type === 'templates')[0];
                        const companyType = data.included.filter(el => el.type === 'company_types')[0];
                        const incidentStatuses = data.included.filter(el => el.type === 'incident_statuses')
                            .map(i => {
                                return {
                                    value: i.id,
                                    text: i.attributes.name,
                                    isDefault: i.attributes.is_default
                                };
                            });
                        const incidentEmailsIds = data.included.filter(el => el.type === 'incident_kind_employees_mails').map(el => el.attributes.employee_id);
                        const incidentEmails = data.included.filter(el => (el.type === 'employees') && incidentEmailsIds.includes(parseInt(el.id)))
                            .map(i => {
                                return {
                                    value: i.id,
                                    text: `${i.attributes.last_name} ${i.attributes.first_name} (${i.attributes.email})`
                                };
                            });
                        const incidentPhonesIds = data.data.relationships.employee_phones.data.map(el => parseInt(el.id));
                        const incidentPhones = data.included.filter(el => (el.type === 'employees') && incidentPhonesIds.includes(parseInt(el.id)))
                            .map(i => {
                                return {
                                    value: i.id,
                                    text: `${i.attributes.last_name} ${i.attributes.first_name} (${i.attributes.phone})`
                                };
                            });
                        if (template) {
                            activeIncidentTypePayload.templateId = template.id;
                        }
                        if (companyType) {
                            activeIncidentTypePayload.company_typeId = companyType.id;
                        }
                        if (incidentStatuses) {
                            activeIncidentTypePayload.incident_statuses = incidentStatuses;
                        }
                        if (incidentEmails) {
                            activeIncidentTypePayload.notifications_mail = incidentEmails;
                            activeIncidentTypePayload.previousNotifications_mail = incidentEmails;
                        }
                        if (incidentPhones) {
                            activeIncidentTypePayload.notifications_phone = incidentPhones;
                        }
                    }
                    commit('SET_ACTIVE_INCIDENT_TYPE', activeIncidentTypePayload);
                });
        },
        getActiveIncidentStatus ({ commit }, id) {
            return incidentsApi.getActiveIncidentStatus(id)
                .then(({ data }) => {
                    const activeIncidentStatusPayload = {
                        id: data.data.id,
                        ...data.data.attributes
                    };
                    if (data?.included) {
                        const incidents = data.included.filter(el => el.type === 'incidents')
                            .map(i => {
                                return {
                                    value: i.id,
                                    text: i.attributes.number
                                };
                            });
                        const incidentKindStatuses = data.included.filter(el => el.type === 'incident_kind_statuses')
                            .map(i => {
                                return {
                                    value: i.id,
                                    text: i.id
                                };
                            });
                        const incidentKinds = data.included.filter(el => el.type === 'incident_kinds')
                            .map(i => {
                                return {
                                    value: i.id,
                                    text: i.attributes.name
                                };
                            });
                        if (incidents) {
                            activeIncidentStatusPayload.incidents = incidents;
                        }
                        if (incidentKindStatuses) {
                            activeIncidentStatusPayload.incident_kind_statuses = incidentKindStatuses;
                        }
                        if (incidentKinds) {
                            activeIncidentStatusPayload.incident_kinds = incidentKinds;
                        }
                    }
                    commit('SET_INCIDENT_STATUS_DATA', activeIncidentStatusPayload);
                });
        },
        createIncident ({ state, commit }, answers = null, incidentType = null) {
            const payload = state.incidentData.getCreateDataV2();

            return incidentsApiV2.createIncident(payload)
                .then((res) => {
                    const incidentId = res.data?.id;

                    if (Array.isArray(answers) && answers.length > 0) {
                        const inspectionId = res.data?.inspections?.[0];
                        const date = DATE_NOW_IN_LOCAL_TIME();

                        const answersData = answers.map(answer => (
                            {
                                inspection: inspectionId,
                                question: answer.question,
                                question_type: answer.question_type,
                                question_parameters: answer.question_parameters,
                                answer: answer.answer?.toString() || '',
                                question_template: answer.question_template,
                                minimum: answer.minimum,
                                sms_content: answer.sms_message_content,
                                is_invalid: false,
                                time_sms_send: null,
                                seq: answer.seq,
                                incident: incidentType,
                                worker_id_inserted: answer.worker_id_inserted,
                                worker_id_updated: answer.worker_id_updated,
                                date_inserted: date,
                                date_updated: null,
                                config: answer.config,
                                answer_date_time: date,
                                not_applicable: answer.not_applicable
                            }
                        ));

                        inspectionsApi.answerControlFields(answersData).then(() => {
                            incidentId && incidentsApiV2.confirmCreation(incidentId);
                        });
                    } else {
                        incidentId && incidentsApiV2.confirmCreation(incidentId);
                    }

                    commit('CLEAR_INCIDENT_DATA');
                });
        },
        createIncidentType ({ state, commit }) {
            const payload = state.incidentTypeData.getCreateData();

            return incidentsApi.createIncidentType(payload)
                .then(() => {
                    commit('CLEAR_INCIDENT_TYPE_DATA');
                });
        },
        createIncidentStatus ({ state, commit }) {
            const payload = state.incidentStatusData.getCreateData();

            return incidentsApi.createIncidentStatus(payload)
                .then(() => {
                    commit('CLEAR_INCIDENT_STATUS_DATA');
                });
        },
        updateIncident ({ state, commit }) {
            const payload = state.incidentData.getUpdateData();

            return incidentsApiV2.updateIncident(state.editedIncidentId, payload)
                .then(() => {
                    commit('CLEAR_INCIDENT_DATA');
                });
        },
        updateIncidentType ({ state, commit }) {
            const payload = state.incidentTypeData.getUpdateData(state.editedIncidentTypeId);

            return incidentsApi.updateIncidentType(state.editedIncidentTypeId, payload)
                .then(() => {
                    commit('CLEAR_INCIDENT_TYPE_DATA');
                });
        },
        updateIncidentStatus ({ state, commit }) {
            const payload = state.incidentStatusData.getUpdateData(state.editedIncidentStatusId);

            return incidentsApi.updateIncidentStatus(state.editedIncidentStatusId, payload)
                .then(() => {
                    commit('CLEAR_INCIDENT_STATUS_DATA');
                });
        },
        deleteIncident (context, id) {
            return incidentsApiV2.deleteIncident(id);
        },
        deleteIncidentType (context, id) {
            return incidentsApi.deleteIncidentType(id);
        },
        deleteIncidentStatus (context, id) {
            return incidentsApi.deleteIncidentStatus(id);
        },
        clearIncidentData ({ commit }) {
            commit('SET_ACTIVE_INCIDENT', null);
            commit('SET_INCIDENT_HISTORY', []);
            commit('SET_INCIDENT_COMMENTS', []);
            commit('SET_INCIDENT_COMMENT_TOTAL_AMOUNT', null);
            commit('manageInspection/SET_INSPECTION_QUESTIONS', [], { root: true });
            commit('manageInspection/SET_INSPECTION_QUESTIONS_TOTAL_AMOUNT', null, { root: true });
            commit('manageTemplate/SET_ACTIVE_TEMPLATE', null, { root: true });
        },
        clearIncidentTypeData ({ commit }) {
            commit('SET_ACTIVE_INCIDENT_TYPE', null);
            commit('CLEAR_INCIDENT_TYPE_DATA');
            commit('manageTemplate/SET_ACTIVE_TEMPLATE', null, { root: true });
        },
        updateManyStatuses (context, data) {
            const payload = {
                data: {
                    type: 'incidents',
                    incidents_ids_to_update: data.incidentsIdsToUpdate,
                    incident_status_id: data.incidentStatusId
                }
            };

            return incidentsApi.updateManyStatuses(payload);
        },
        deleteManyIncidents (context, data) {
            const payload = {
                data: {
                    type: 'incidents',
                    incidents_ids_to_delete: data
                }
            };

            return incidentsApi.deleteManyIncidents(payload);
        },
        getCommentsPerIncident ({ commit }, payload) {
            commit('SET_INCIDENT_COMMENTS', []);

            return incidentsApiV2.getIncidentComments(payload.id)
                .then(({ data }) => {
                    commit('SET_INCIDENT_COMMENTS', data.results || []);
                    commit('SET_INCIDENT_COMMENT_TOTAL_AMOUNT', data.count || '');
                });
        },
        getCommentsPerIncidentLazyLoading ({ state, commit }, payload) {
            return incidentsApiV2.getIncidentComments(payload.id)
                .then(({ data }) => {
                    const result = [...state.incidentComments].concat(data.results);
                    commit('SET_INCIDENT_COMMENTS', result || []);
                    commit('SET_INCIDENT_COMMENT_TOTAL_AMOUNT', data.count || []);
                });
        },
        createComment ({ state }) {
            const payload = {
                comment: state.incidentCommentContent,
                date: DATE_NOW_IN_LOCAL_TIME()
            };
            return incidentsApiV2.createIncidentComment(state.activeIncident.id, payload);
        },
        getIncidentPDF ({ rootState }, id) {
            const queryParams = new URLSearchParams();
            queryParams.set('locale', rootState.locale.currentLocale);

            const url = `${incidentsApiV2.getPdfReport(id)}?${queryParams}`;

            return forceFileDownload(url, `incident_${id}.pdf`, false, true);
        },
        getCurrentIncidentQuestions ({ state, commit, rootState }, id) {
            const incident = rootState.incidents.incidentTypes.find(incidentType => incidentType.id === id);

            return inspectionsApi.getInspectionQuestions(incident.templateId)
                .then(({ data }) => {
                    const editableQuestions = data.filter(question => !WEB_TYPES_TO_SKIP.includes(question.question_type));
                    commit('SET_INCIDENT_QUESTIONS', editableQuestions);
                });
        }
    }
};
