import citiesApi from '@/utils/api/cities';
import { getField, updateField } from 'vuex-map-fields';
import { RELATIONSHIPS } from '@/helpers/jsonapiHelpers';

const defaultCityData = () => ({
    countryId: null,
    zipCode: null,
    name: null
});

const parseData = (state) => ({
    data: {
        type: 'cities',
        attributes: {
            name: state.cityData.name,
            zip_code: state.cityData.zipCode
        },
        relationships: {
            country: {
                data: RELATIONSHIPS.CREATE.COUNTRY(state.cityData.countryId)
            }
        }
    }
});

const getId = (string) => string.substring(string.lastIndexOf('/') + 1);

const convertCityData = (arr) => {
    return arr.map(el => ({
        id: el.id,
        ...el.attributes,
        countryId: getId(el.relationships.country.links.related)
    }));
};

export default {
    namespaced: true,
    state: {
        rawCities: [],
        totalCitiesAmount: null,
        cityFormVisibility: false,
        activeCityId: null,
        cityData: defaultCityData()
    },
    getters: {
        getField,
        filterableCities: state => state.rawCities.map(el => {
            return {
                value: el.id,
                text: el.name + ' (' + el.zip_code + ')'
            };
        }),
        filterableZipCodes: state => state.rawCities.map(el => {
            return {
                value: el.id,
                zip_code: el.zip_code,
                id: el.id,
                name: el.name,
                text: el.name + ' ' + el.zip_code + '(' + el.country_code + ')'
            };
        })
    },
    mutations: {
        updateField,
        SET_RAW_CITIES (state, payload) {
            state.rawCities = payload;
        },
        SET_TOTAL_CITIES_AMOUNT (state, payload) {
            state.totalCitiesAmount = payload;
        },
        SET_CITY_FORM_VISIBILITY (state, payload) {
            state.cityFormVisibility = payload;
        },
        SET_ACTIVE_CITY_ID (state, payload) {
            state.activeCityId = payload;
        },
        RESET_CITY_DATA (state) {
            state.cityData = defaultCityData();
        },
        SET_CITY_DATA (state, payload) {
            state.cityData = {
                countryId: payload.countryId,
                zipCode: payload.zip_code,
                name: payload.name
            };
        }
    },
    actions: {
        getCities ({ commit }, additionalParams = null) {
            commit('SET_RAW_CITIES', []);

            const params = {
                'stats[total]': 'count',
                sort: 'name'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return citiesApi.getCities(params)
                .then(({ data }) => {
                    commit('SET_RAW_CITIES', convertCityData(data.data));

                    if (data.meta.stats) {
                        commit('SET_TOTAL_CITIES_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getCitiesLazyLoading ({ commit, state }, additionalParams = null) {
            const params = {
                'stats[total]': 'count',
                sort: 'name'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return citiesApi.getCities(params)
                .then(({ data }) => {
                    const result = [...state.rawCities].concat(convertCityData(data.data));

                    commit('SET_RAW_CITIES', result);

                    if (data.meta.stats) {
                        commit('SET_TOTAL_CITIES_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        createCity ({ state }) {
            const data = parseData(state);

            return citiesApi.createCity(data);
        },
        deleteCity (context, id) {
            return citiesApi.deleteCity(id);
        },
        updateCity ({ state }) {
            const data = parseData(state);
            data.data.id = state.activeCityId;

            return citiesApi.updateCity(state.activeCityId, data);
        }
    }
};
