/* eslint-disable camelcase */
import { configure, extend, localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import pl from 'vee-validate/dist/locale/pl.json';
import es from 'vee-validate/dist/locale/es.json';
import uk from 'vee-validate/dist/locale/uk.json';
import ru from 'vee-validate/dist/locale/ru.json';
import sl from 'vee-validate/dist/locale/sl.json';
import { i18n } from '@/plugins/i18n';

import {
    between,
    double,
    email,
    integer,
    is_not,
    max,
    max_value,
    min,
    min_value,
    required
} from 'vee-validate/dist/rules';
import {
    IS_CURRENT_DATE_LESS_OR_EQUAL,
    IS_DATE_GRATER,
    IS_DATE_GRATER_OR_EQUAL,
    IS_TIME_GREATER_THAN_NOW
} from '@/helpers/dates';

configure({
    mode: 'eager'
});

localize({
    en,
    pl,
    es,
    uk,
    ru,
    sl
});

extend('is_not', {
    ...is_not
});
extend('required', {
    ...required
});
extend('double', {
    ...double
});
extend('between', {
    ...between
});

extend('email', {
    ...email
});

extend('integer', {
    ...integer
});

extend('minValue', {
    ...min_value
});

extend('maxValue', {
    ...max_value
});

extend('password', {
    params: ['target'],
    validate (value, { target }) {
        return value === target;
    },
    message: () => i18n.t('custom_validation.pass_match_error')
});
extend('min', {
    ...min
});
extend('min_value', {
    ...min_value
});
extend('max', {
    ...max
});
extend('decimal', {
    validate: value => {
        const regex = /^[0-9]+(\.[0-9]{1,})?$/i;
        return regex.test(value);
    }
});

extend('coordinates', {
    validate: value => {
        const regex = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/i;
        return regex.test(value);
    }
});

extend('isCurrentDateLessOrEqual', {
    validate: value => {
        return IS_CURRENT_DATE_LESS_OR_EQUAL(new Date(value));
    },
    message: () => i18n.t('custom_validation.date_range_error')
});

extend('isDateGreaterOrEqual', {
    params: ['target'],
    validate: (value, { target }) => {
        return IS_DATE_GRATER_OR_EQUAL(new Date(value), new Date(target));
    },
    message: () => i18n.t('custom_validation.date_range_error')
});

extend('isDateGreaterThanNow', {
    params: ['target'],
    validate: (value, { target }) => {
        return IS_DATE_GRATER(new Date(value), new Date());
    },
    message: () => i18n.t('custom_validation.date_range_error')
});

extend('isDateGreaterOrEqualNow', {
    validate: (value) => {
        return IS_DATE_GRATER_OR_EQUAL(new Date(value), new Date());
    },
    message: () => i18n.t('custom_validation.date_range_error')
});

extend('isTimeGreaterThanNow', {
    params: ['target'],
    validate: (value, { target }) => {
        return IS_DATE_GRATER(new Date(target), new Date()) || IS_TIME_GREATER_THAN_NOW(value);
    },
    message: () => i18n.t('custom_validation.date_range_error')
});

extend('isDateTimeGreaterThan', {
    params: ['targetTime', 'targetDate', 'thisDate'],
    validate: (value, { targetTime, targetDate, thisDate }) => {
        return IS_DATE_GRATER_OR_EQUAL(new Date(thisDate), new Date(targetDate)) && (
            IS_DATE_GRATER(new Date(thisDate), new Date(targetDate)) || value > targetTime
        );
    },
    message: () => i18n.t('custom_validation.date_range_error')
});

extend('phone', {
    validate: value => {
        const regex = /^[0-9+\-\s]+/i;
        return regex.test(value);
    },
    message: () => i18n.t('custom_validation.phone_error')
});
extend('includesDefault', {
    validate: value => {
        if (Array.isArray(value)) {
            return value.some(f => f.isDefault);
        } else return false;
    },
    message: () => i18n.t('custom_validation.includes_default')
});

extend('capitalLetter', {
    validate: value => {
        const regex = /[\p{Lu}]+/u;
        return regex.test(value);
    },
    message: () => i18n.t('custom_validation.capital_letter')
});

extend('requiredDuration', {
    validate: (value) => {
        return value && value.HH !== '' && value.mm !== '';
    },
    message: () => i18n.t('custom_validation.duration_required')
});

extend('durationGreaterThan', {
    params: ['target'],
    validate: (value, { target }) => {
        return value && target && `${value.HH}${value.mm}` >= `${target.HH}${target.mm}`;
    },
    message: () => i18n.t('custom_validation.duration_error')
});
