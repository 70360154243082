const prefix = '/v2';

const labels = {
    'sign_in': '/auth/token/',
    'sign_out': '/auth/logout/',
    'refresh_token': '/auth/token/refresh/',
    'inspection_templates': '/inspections/template-fields/',
    'inspection_questions': '/inspections/templates/:id/questions/',
    'control_fields': '/inspections/control-fields/',
    'control_fields/bulk_create': '/inspections/control-fields/bulk-create/',
    'subcontractors_types': '/subcontractors/types/',
    'manage_subcontractors_types': '/subcontractors/types/:id/',
    'users_assigned_subcontractors': '/users/assigned-subcontractors/',
    'subcontractors': '/subcontractors/',
    'manage_subcontractors': '/subcontractors/:id/',
    'manage_site': '/locations/:id/',
    'import_locations_attachment': '/locations/:id/upload-file/',
    'remove_locations_attachment': '/locations/:id/delete-file/',
    'site_groups': '/locations/groups/',
    'manage_site_groups': '/locations/groups/:id/',

    'inspection_schedules': '/inspections/schedule/',
    'manage_inspection_schedules': '/inspections/schedule/:id/',

    'osm_device_employee': '/osm/device-employee/',
    'osm_device_employee_id': '/osm/device-employee/:id/',

    'periodicPointReport': {
        'send': '/reports/periodic/point/send-now/',
        'list': '/reports/periodic/point/',
        'manage': '/reports/periodic/point/:id/'
    },
    'periodicLocationReport': {
        'send': '/reports/periodic/location/send-now/',
        'list': '/reports/periodic/location/',
        'manage': '/reports/periodic/location/:id/'
    },
    'periodicEmployeeReport': {
        'send': '/reports/periodic/employee/send-now/',
        'list': '/reports/periodic/employee/',
        'manage': '/reports/periodic/employee/:id/'
    },
    'periodicPointsNoScannedReport': {
        'send': '/reports/periodic/point-not-scanned/send-now/',
        'list': '/reports/periodic/point-not-scanned/',
        'manage': '/reports/periodic/point-not-scanned/:id/'
    },
    'incrementalPointsNoScannedReport': {
        'send': '/reports/periodic/incremental-point-not-scanned/send-now/',
        'list': '/reports/periodic/incremental-point-not-scanned/',
        'manage': '/reports/periodic/incremental-point-not-scanned/:id/'
    },
    'incidents': {
        'list': '/incidents/',
        'manage': '/incidents/:id/',
        'confirm_creation': '/incidents/:incident_id/confirm-creation/',
        'send_report': '/incidents/:id/send_report/',
        'get_pdf_report': '/incidents/:id/get_pdf_report/'
    },
    'inspections': {
        'list': '/inspections/',
        'manage': '/inspections/:id/',
        'templates_by_point': '/inspections/templates/by_point/',
        'send_report': '/inspections/:id/send_report/',
        'get_pdf_report': '/inspections/:id/get_pdf_report/'
    },
    'tracks': {
        'list': '/tracks/',
        'manage': '/tracks/:id/',
        'bulk_delete': '/tracks/bulk-delete/'
    },
    'comments': {
        'send': '/incidents/:incident_id/comments/',
        'list': '/incidents/:incident_id/comments/'
    }
};

export const getV2Endpoint = (label, params = null, withPrefix = true) => {
    const _prefix = withPrefix ? prefix : '';
    let url = '';
    if (typeof label === 'object' && label.group && label.endpoint && labels[label.group] && labels[label.group][label.endpoint]) {
        url = `${_prefix}${labels[label.group][label.endpoint]}`;
    } else if (labels[label]) {
        url = `${_prefix}${labels[label]}`;
    }

    if (!params) {
        return url;
    } else {
        if (url.indexOf(':') === -1) {
            return url;
        } else {
            if (typeof (params) === 'object') {
                for (const param in params) {
                    url = url.replace(':' + param, params[param]);
                }
            }
            return url;
        }
    }
};
