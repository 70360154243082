import { DateTime, Duration } from 'luxon';
import humanizeDuration from 'humanize-duration';

export const DATE_NOW_IN_LOCAL_TIME = () => {
    const date = DateTime.now();
    const offset = DateTime.now().offset;
    return date.toUTC(offset).toISO().split('+')[0] + 'Z';
};

export const PARSE_DATE = (dateString, format = 'yyyy-MM-dd', locale = 'pl') => {
    if (typeof dateString === 'object') {
        return DateTime.fromJSDate(dateString).setLocale(locale).toFormat(format);
    } else if (typeof dateString === 'string') {
        return DateTime.fromISO(dateString).setLocale(locale).toFormat(format);
    }
};

export const PARSE_DATETIME = (dateString) => {
    if (typeof dateString === 'object') {
        return DateTime.fromJSDate(dateString).toFormat('yyyy-MM-dd HH:mm:ss');
    } else if (typeof dateString === 'string') {
        return DateTime.fromISO(dateString).toFormat('yyyy-MM-dd HH:mm:ss');
    }
};

export const CONVERT_TO_UTC = (dateString) => {
    const date = DateTime.fromFormat(dateString, 'yyyy-MM-dd TT');
    return date.toUTC().toFormat('yyyy-MM-dd TT');
};

export const CONVERT_FROM_UTC = (dateString) => {
    const date = DateTime.fromISO(dateString);
    const offset = DateTime.now().offset;
    return date.toUTC(offset).toISO();
};

export const GET_BEGINNING_OF_CURRENT_MONTH = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    const day = '01';

    return `${year}-${month}-${day}`;
};

export const GET_STRING_DATE = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();

    return `${year}-${month}-${day}`;
};

export const GET_BEGINNING_OF_MONTH = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    const day = '01';

    return `${year}-${month}-${day}`;
};

export const PARSE_TIME = (dateString) => {
    if (typeof dateString === 'object') {
        return DateTime.fromJSDate(dateString).toFormat('TT');
    } else if (typeof dateString === 'string') {
        return DateTime.fromISO(dateString).toFormat('TT');
    }
};

export const GET_HUMANIZE_DURATION = (dateTimeString, lang = 'en') => {
    const duration = Duration.fromObject({
        seconds: dateTimeString
    });
    return humanizeDuration(duration.valueOf(), { language: lang, units: ['h', 'm', 's'] });
};

export const GET_END_OF_CURRENT_MONTH = () => {
    const date = new Date();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return PARSE_DATE(lastDay);
};

export const GET_END_OF_MONTH = (dateString) => {
    const date = new Date(dateString);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return PARSE_DATE(lastDay);
};

export const IS_CURRENT_DATE_LESS_OR_EQUAL = (date) => {
    const currentDate = new Date(new Date().toDateString());
    const clickedDate = new Date(date.toDateString());

    return currentDate <= clickedDate;
};

export const IS_DATE_GRATER_OR_EQUAL = (date, referalDate) => {
    const _date = new Date(date.toDateString());
    const _referalDate = new Date(referalDate.toDateString());

    return _date >= _referalDate;
};

export const IS_DATE_GRATER = (date, referalDate) => {
    const _date = new Date(date.toDateString());
    const _referalDate = new Date(referalDate.toDateString());

    return _date > _referalDate;
};

export const IS_TIME_GREATER_THAN_NOW = (time) => {
    const now = DateTime.now().toFormat('HH:mm:ss');
    return time > now;
};

export const GET_MONTH = (dateStr) => {
    return DateTime.fromISO(dateStr).month;
};

export const GET_CURRENT_MONTH = () => {
    const date = new Date();
    const dt = DateTime.fromJSDate(date);

    return dt.month;
};

export const PARSE_TIME_FROM_DATESTRING = (dateString) => {
    const parsedTime = DateTime.fromFormat(dateString.slice(0, 19), 'yyyy-MM-dd HH:mm:ss');
    const offset = DateTime.now().offset;
    return parsedTime.plus({ minutes: offset }).toFormat('HH:mm');
};

export const PARSE_TIME_FROM_ISO_DATESTRING = (dateString, inverseOffset = false) => {
    const parsedTime = DateTime.fromISO(dateString);
    const offset = DateTime.now().offset;
    return parsedTime.plus({ minutes: inverseOffset ? -offset : offset }).toFormat('HH:mm');
};

export const PARSE_TIME_ONLY_FROM_ISO_DATESTRING = (timeString, inverseOffset = false) => {
    const today = DateTime.now().toFormat('yyyy-MM-dd');
    const parsedTime = DateTime.fromISO(`${today}T${timeString}`);
    const offset = DateTime.now().offset;
    return parsedTime.plus({ minutes: inverseOffset ? -offset : offset }).toFormat('HH:mm:ss');
};

export const TODAY_STRING = GET_STRING_DATE(new Date());

export const GET_LATER_DATE = (dateString, secondDateString) => {
    const date = DateTime.fromISO(dateString);
    const secondDate = DateTime.fromISO(secondDateString);

    return date > secondDate ? dateString : secondDateString;
};

export const GET_SECONDS_FROM_TIME = (timeString) => {
    return timeString?.split(':').reduce((acc, time) => (60 * acc) + +time);
};
