import { STEP_ELEMENT_TYPE_ENUM } from '@/constants/cyclicRevision';

class Track {
    constructor (params) {
        this.id = params?.id || null;
        this.name = params?.name || null;
        this.assignedEmployee = params?.assignedEmployee || {
            id: null,
            name: null
        };
        this.point = params?.point || {
            id: null,
            name: null
        };
        this.incidentType = params?.incidentType || {
            id: null,
            name: null
        };
        this.inspectionTemplate = params?.inspectionTemplate || {
            id: null,
            name: null
        };
        this.startDate = params?.startDate || null;
        this.endDate = params?.endDate || null;
        this.startTime = params?.startTime || null;
        this.endTime = params?.endTime || null;
        this.timeToNotifyBeforeSequence = params?.timeToNotifyBeforeSequence || null;
        this.repetitionScheme = params?.repetitionScheme || null;
        this.cycleFrequencyInDays = params?.cycleFrequencyInDays || null;
        this.repetitionDates = params?.repetitionDates || null;
        this.repetitionWeekdays = params?.repetitionWeekdays || null;
        this.sequence = params?.sequence || {
            type: null,
            durationTime: null,
            repetitionPattern: null,
            repetitionCount: null,
            steps: []
        };
    }

    formatDuration (time) {
        if (time) {
            return time.HH * 3600 + time.mm * 60;
        }
        return null;
    }

    timeToDuration (time) {
        if (time) {
            const [HH, mm] = time.split(':');
            return {
                HH,
                mm
            };
        }
        return null;
    }

    #getBaseJSONStructure () {
        const data = {
            ...this.getTrackAttributes()
        };
        data.sequence = this.getSequence();
        return data;
    }

    getSteps () {
        return this.sequence.steps.map((step, index) => {
            return {
                order: index,
                time_since_sequence_start: this.formatDuration(step.timeSinceSequenceStart) || 0,
                tolerance: this.formatDuration(step.tolerance) || 0,
                verification_way: step.verificationWay,
                template_id: step.template?.id || null,
                point_id: step.elementType === STEP_ELEMENT_TYPE_ENUM.POINT ? step.id : null,
                employee_id: step.elementType === STEP_ELEMENT_TYPE_ENUM.EMPLOYEE || step.elementType === STEP_ELEMENT_TYPE_ENUM.ELEMENT ? step.id : null
            };
        });
    }

    getTrackAttributes () {
        return {
            name: this.name,
            point_id: this.point.id,
            assigned_employee_id: this.assignedEmployee.id,
            incident_type_id: this.incidentType.id,
            inspection_template_id: this.inspectionTemplate.id,
            start_date: this.startDate ? this.startDate : this.repetitionDates?.sort()?.[0],
            end_date: this.endDate ? this.endDate : this.repetitionDates?.sort()?.[this.repetitionDates.length - 1],
            start_time: this.startTime,
            end_time: this.endTime,
            time_to_notify_before_sequence: this.formatDuration(this.timeToNotifyBeforeSequence) || 0,
            notify_by_email: Boolean(this.notifyByEmail),
            repetition_scheme: this.repetitionScheme,
            cycle_frequency_in_days: this.cycleFrequencyInDays || 0,
            repetition_dates: this.repetitionDates,
            repetition_weekdays: this.repetitionWeekdays
        };
    }

    getSequence () {
        return {
            type: this.sequence.type,
            duration_time: this.formatDuration(this.sequence.durationTime) || 0,
            repetition_pattern: this.sequence.repetitionPattern,
            repetition_count: this.sequence.repetitionCount,
            steps: this.getSteps()
        };
    }

    getDataForCreate () {
        return this.#getBaseJSONStructure();
    }

    parseData (payload) {
        this.id = payload.id;
        this.name = payload.name;
        this.assignedEmployee = {
            id: payload.assigned_employee_id.toString() || null,
            name: payload.assigned_employee_name
        };
        this.point = {
            id: payload.point_id?.toString() || null,
            name: payload.point_name
        };
        this.incidentType = {
            id: payload.incident_type_id?.toString() || null,
            name: payload.incident_type_name
        };
        this.inspectionTemplate = {
            id: payload.inspection_template?.toString() || null,
            name: payload.inspection_template_name
        };
        this.startDate = payload.start_date;
        this.endDate = payload.end_date;
        this.startTime = payload.start_time;
        this.endTime = payload.end_time;
        this.timeToNotifyBeforeSequence = this.timeToDuration(payload.time_to_notify_before_sequence);
        this.notifyByEmail = payload?.notify_by_email;
        this.cycleFrequencyInDays = payload.cycle_frequency_in_days;
        this.repetitionScheme = payload.repetition_scheme;
        this.repetitionDates = payload.repetition_dates;
        this.repetitionWeekdays = payload.repetition_weekdays;
        this.sequence = {
            type: payload.sequence.type,
            durationTime: this.timeToDuration(payload.sequence.duration_time),
            repetitionPattern: payload.sequence.repetition_pattern,
            repetitionCount: payload.sequence.repetition_count,
            steps: [],
            activeSteps: payload.sequence.steps.map(step => {
                return {
                    order: step.order,
                    sequence: step.sequence,
                    timeSinceSequenceStart: this.timeToDuration(step.time_since_sequence_start),
                    tolerance: this.timeToDuration(step.tolerance),
                    verificationWay: step.verification_way,
                    template: {
                        id: step.template_id?.toString() || null,
                        name: step.template_name
                    },
                    point: {
                        id: step.point_id?.toString() || null,
                        name: step.point_name,
                        clientId: step.client_id?.toString() || null,
                        locationId: step.location_id?.toString() || null
                    },
                    employee: {
                        id: step.employee_id?.toString() || null,
                        name: step.employee_name
                    },
                    parent_id: step.order === 0 ? null : step.order,
                    value: step.template_id || step.verification_way,
                    name: step.employee_name || step.point_name
                };
            })
        };
    }
}

export default Track;
